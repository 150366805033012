@import '../../variables';

#themeToggler{
    height:wrap;
    width: wrap;
    position: absolute;
    border-radius: 6px;
    top: 5%;
    left: 80%;
    // To Retain Borders as per design
    #lightModeButton{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    #darkModeButton{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .selected{
        background-color:$lightSecondaryColor;
    }
    span{
        display: inline-block;
        padding:5px;

    }
    span:hover{
        background-color: $lightSecondaryColor;
        cursor: pointer;
    }

}

.light-toggler{
    background-color:$lightPrimaryText;
    color: #fff;
    font-weight: 600;
    .selected{
        color: $darkBackground;
    }
    span:hover{
        color: $darkBackground;
    }
}
.dark-toggler{
    background-color:$lightBackground;
    color: $lightPrimaryText;
}

button {
    height: 100%;
}


@media only screen and (max-width: $maxWidth) {
    #themeToggler{
        top: 5%;
        left: 5%;
    }
   }