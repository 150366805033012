@import '../../variables';


#landing{
    padding-top: 0vh;
    padding-bottom: 10vh;
    margin: 0px;
    width: 100%;
    height: wrap;
    min-height: 100vh;
    h1{
        font-size:2.2rem;
    }
    p{
        margin-top: -1rem;
        font-size: 1.1rem;
    }
    img {
        margin-top: 30vh;
        width: 18vw;
    }
    #MyRole{
        font-family: 'Oregano',cursive;
        font-size: 1.3rem;
    }

}

.light-landing {
    background-color: $lightBackground;
    h1{
        color: $lightPrimaryText;
    }
    p{
        color: $lightTextColorGray;
    }
}

.dark-landing {
    background-color: $darkBackground;
    h1{
        color: $darkSecondaryColor;
    }
    p{
        color: $lightBackground;
    }
}

@media only screen and (max-width: $maxWidth) {
    #landing{
        padding-top: 2vh;
        height: 100vh;
        img {
            width: 50vw;
            height: 10rem;
            margin-top: 20vh;
        }
        h1{
            font-size:1.5rem;
        }
        p{
            font-size: 1rem;
        }

    }

   }