  
  $lightBackground:#FAFAFA;
  $darkBackground:#171717;
  // Text Colors
  $lightPrimaryText:#3F3D56;
  $darkPrimaryText:#FAFAFA;
  $darkSecondaryColor:#FDCA13;
  $lightSecondaryColor:#66BB6A;
  $lightTextColorGray:#828282;
  $darkTextColorBlue:#0d47a1;
  // Container Colors
  $lightContainerColor:#FFFFFF;
  $darkContainerColor:#202D36;
  $maxWidth: 800px;

  $export_variables:(
    'lightBackground':#FAFAFA,
    'darkBackground':#171717,
    'lightSecondaryColor':#66BB6A,
    'darkSecondaryColor':#FDCA13,
  );

  :export {
    @each $key, $value in $export_variables {
      #{unquote($key)}: $value;
    }
  }