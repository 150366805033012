@import '../../variables';

#navwidget{
 width: 3rem;
 height:3rem;
 top: 85vh;
 position: fixed;
 margin-right: 2vw;
 z-index: 10;
 border-radius: 5px;
 right: 0;
 border: 0.4px solid gray;
 #close-btn{
    position: absolute;
    color: $darkBackground;
    top: 0;
    margin-right: 10px;
    margin-top: 10px;
    right: 0;
}
 #navButtonsContainer{
     margin-top: 10px;
     display: flex;
     margin-left: 10%;
     flex-wrap: wrap;

     #close-btn:hover{
         cursor: pointer;
     }
 }
 .nav-btn{
    width: fit-content;
    padding: 1rem;
    text-align: center;
    h2{
        text-align: center;
        margin-top: 5px;
        font-family: 'Oregano',cursive;
        font-size: 0.9rem;
     }
 }

 #burger{
     text-align: center;
     padding: 0.7rem;
     .line{
         margin-left: auto;
         margin-right: auto;
         margin-bottom: 0.35rem;
         height: 0.2rem;
         width: 100%;
         border: none;
     }
 }

 #burger:hover{
     cursor: pointer;
 }
}

.light-navwidget{
    background-color: rgba(102, 187, 106,1);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
    #navButtonsContainer{
        color: $lightBackground;
        a:hover{
            color: $lightPrimaryText;
        }
        a{
            color: $lightBackground;
        }
        #close-btn{
            color: $lightBackground;
        }
    }

    #burger{
        .line{
            background-color: #fff;
        }
    }
}
.dark-navwidget{
    background-color: rgba(253, 202, 19,1);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
    #navButtonsContainer{
        color: $darkBackground;
        a:hover{
            color: $darkTextColorBlue;
        }
        a{
            color: $darkBackground;
        }
        #close-btn{
            color: $darkBackground;
        }
    }

    #burger{
        .line{
            background-color: $darkBackground;
        }
    }
}
@keyframes expanddesktop{
    from{ width: 3rem;
        height:3rem;}
    to{
        top: 70vh;
        height: 12rem;
        width: 18rem;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
}
@keyframes expandmobile{
    from{ width: 3rem;
        height:3rem;}
    to{
        top: 0;
        right: 0;
        padding-top: 2rem;
        height: 100vh;
        margin-right: 0;
        width: 5rem;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
}
@keyframes shrinkdesktop{
    from{  top: 70vh;
        height: 12rem;
        width: 18rem;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px;}
    to{
        height: 3rem;
        width: 3rem;
        top: 85vh;
    }
}
@keyframes shrinkmobile{
    from{ top: 0;
        right: 0;
        padding-top: 1rem;
        height: 100vh;
        margin-right: 0;
        width: 5rem;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px;}
    to{
        width: 3rem;
        height:3rem;
        top: 85vh;
        position: fixed;
        margin-right: 2vw;
    }
}

@keyframes fadeout{
    from{opacity: 1;}
    to{opacity: 0;display: none;}
}
@media only screen and (max-width: $maxWidth) {
    #navwidget{
        #navButtonsContainer{
            #close-btn{
                position: relative !important;
                color: $darkBackground;
                display: block;
                margin-left: auto;
                margin-right: auto;
                height: wrap !important;
                text-align: center !important;
            }
        }   
    }

}