@import './variables';
  .App{
    margin: 0px;
    padding: 0px;
    text-align: center;

  
    .section_heading{
        margin-left: 4%;
        width: fit-content;
        font-family: 'Oregano',cursive;
        font-weight: normal;
        font-size: 3.5rem;
    }

  }
  .app-light{
    .section_heading{
      color:$lightPrimaryText;
      border-bottom: 4px solid $lightPrimaryText;
    }
  }
  .app-dark{
    .section_heading{
      color:$darkSecondaryColor;
      border-bottom: 4px solid $darkSecondaryColor;
    }
  }

  ::-webkit-scrollbar{
    width: 0.5rem;
    border: none;
  }

  ::-webkit-scrollbar-track{
    background-color: #171717;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb{
    border-radius: 6px;
    background-color: $lightSecondaryColor;
  }

  @media only screen and (max-width: $maxWidth) {
    .App{
      .section_heading{
        margin-left: 4%;
        font-size: 2.0rem!important;
      }
    }
  
  }

  .loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: $lightSecondaryColor;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
