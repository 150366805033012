@import '../../variables';
#socialtray{
    margin-top: 1rem;
    * {
        margin-right: 1rem;
    }
    .icon:hover{
        transition-duration: 0.6s;
    }
}
.dark-socialtray {
    .icon{
        color: white;
    }

    .icon:hover {
     color: $darkSecondaryColor;
     cursor: pointer;
    
    }
}
       
.light-socialtray {
    .icon{
        color: $darkBackground;
    }

    .icon:hover {
     color: #2ecc71;
     cursor: pointer;
    }
       
}


@media only screen and (max-width: $maxWidth) {

    #socialtray{
        margin-top: 1.2rem;
        .icon {
            font-size: 2.4rem;
            margin-right: 0.3rem;   
        }
    }

}